<template>
  <div class="content-container content-container--position">
    <img
      v-if="['nl'].includes($i18n.locale)"
      src="/assets/images/beste_website_reizen_2022.svg"
      class="icon-reizen"
    />
    <HgNavbar
      class="hg-navbar"
      :lang="$i18n.locale"
      :user="loggedInUser"
      linkType="nuxt-link"
      :pathName="$nuxt.$route.path"
      :showPremiosLogo="true"
      @onClickBookmark="clickBookmark"
      @onClickUser="clickUser"
    >
      <template #user-menu>
        <nuxt-link :to="localePath('preferences')">
          <span>
            {{ $t('yourProfile') }}
          </span>
        </nuxt-link>
        <template v-if="isInternalUser && editorUrl">
          <a target="_blank" :href="editorUrl" class="editor-button">
            <span> edit this deal </span>
          </a>
        </template>
        <nuxt-link :to="localePath('auth-logout')">
          <span>
            {{ $t('logout') }}
          </span>
        </nuxt-link>
      </template>
    </HgNavbar>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  data: () => ({
    isAuthModalActive: false,
    editorUrl: ''
  }),

  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      loggedInUser: 'auth/loggedInUser',
      isInternalUser: 'auth/isInternalUser'
    }),
    ...mapState({
      /* eslint-disable */
      scriptsLoaded: (state) => state.externalScriptsLoaded,
      allowAds: (state) => state.ads.allowAds,
      selectedDeal: (state) => state.selectedDeal,
      isMobile: (state) => state.isMobile
      /* eslint-enable */
    }),
    debugParams() {
      // Stroeer demo campaign url params
      if (!this.$config.DEBUG_LOGS) return '';
      return '';
    }
  },
  watch: {
    selectedDeal() {
      if (!this.selectedDeal) {
        this.editorUrl = '';
        return '';
      }
      this.setEditorUrl();
    }
  },
  created() {
    if (this.selectedDeal) {
      this.setEditorUrl();
    }
  },

  methods: {
    setEditorUrl() {
      try {
        const pathname = this.selectedDeal.url;
        const segments = pathname.split('/');

        const baseUrl = `https://inside.holidayguru.de/${this.$i18n.locale}`;
        const path = `/${segments[3]}/${segments[4]}/${this.selectedDeal.id}/edit`;
        this.editorUrl = baseUrl + path;
      } catch (e) {
        // throw new Error('Editor URL Error', e); // TODO: Don't set EditorURL "onDealShare"
      }
    },
    toggleAds() {
      this.$store.commit('ads/setAllowAds', !this.allowAds);
    },
    register() {
      const registerPath = this.localePath('/auth/register');
      this.$router.push(registerPath);
    },
    login() {},
    logout() {
      const logoutPath = this.localePath('auth-logout');
      this.$router.push(logoutPath);
    },
    clickBookmark() {
      if (this.isAuthenticated) {
        const bookmarkPath = this.localePath('bookmarks');
        this.$router.push(bookmarkPath);
      } else {
        this.$store.commit('setShowAuthModal', true);
      }
    },
    clickUser() {
      if (this.isAuthenticated) {
        const bookmarkPath = this.localePath('preferences');
        this.$router.push(bookmarkPath);
      } else {
        this.$store.commit('setShowAuthModal', true);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.hg-header--active {
  z-index: 40;
}

.hg-navbar-header {
  width: 180px;
}

@media screen and (min-width: 768px) {
  .hg-navbar-header {
    width: 240px;
  }
}
</style>

<style lang="scss">
.hg-header__nav-mobile a:first-child {
  background-color: initial !important;
}

.hg-header__nav-mobile-container {
  overflow: scroll;
}

.editor-button {
  color: rgb(70 64 0);
  background-color: rgb(255 242 96);
}

.editor-button:hover,
.editor-button:focus {
  background-color: rgb(255 236 26);
}

.hg-header-support-subline {
  width: 100% !important;
  max-width: 270px;
}

@media screen and (min-width: 768px) {
  .hg-header-support-subline {
    padding: 0 2.5rem;
  }
}

.hg-header__nav.hg-header__nav-desktop .promotedLink {
  background-color: #fff260;
  border-radius: 0.5rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  margin-top: 0.35rem;
  margin-bottom: 0.35rem;
}

.hg-header__nav.hg-header__nav-mobile .promotedLink {
  background-color: #fff260;
}

.hg-header__nav.hg-header__nav-mobile .promotedLink:hover,
.hg-header__nav.hg-header__nav-desktop .promotedLink:hover {
  background-color: #ffec1a;
}

.content-container--position {
  position: relative;
}

.icon-reizen {
  width: rem(84);
  height: rem(84);
  position: absolute;
  left: calc(50% - 42px);
  z-index: 15;
  top: rem(20);
  transform: rotate(350deg);

  @media screen and (min-width: 768px) {
    top: rem(30);
  }

  @media screen and (min-width: 1024px) {
    width: rem(94);
    height: rem(94);
    top: rem(-4);
    left: rem(207);
  }
}
</style>
